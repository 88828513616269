
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$neo-clinic-primary: mat.define-palette(mat.$indigo-palette);
$neo-clinic-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
// The warn palette is optional (defaults to red).
$neo-clinic-warn: mat.define-palette(mat.$red-palette);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$neo-clinic-theme: mat.define-light-theme(( color: ( primary: $neo-clinic-primary, accent: $neo-clinic-accent, warn: $neo-clinic-warn, )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@import "./scss/index.scss";
@font-face {
    font-family: "Poppins-Medium";
    src: local("Poppins-Medium"), url(./assets/fonts/POPPINS-MEDIUM.otf) format("truetype");
    font-family: "Poppins-Regular";
    src: local("Poppins-Medium"), url(./assets/fonts/POPPINS-REGULAR.otf) format("truetype");
}

@import "~quill/dist/quill.bubble.css";
@import "@angular/cdk/overlay-prebuilt.css";
@import "../node_modules/@perfectmemory/ngx-contextmenu/src/assets/stylesheets/base";
.tabela-preco {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: min(1600px, 100%);
    margin: auto;
    height: 100%;
}

.card-preco {
    flex: 1;
    max-width: 360px;
    background-color: #fff;
    margin: 20px 10px 5px 20px;
    text-align: left;
    border: solid 1px;
    border-radius: 20px;
    border-color: #006584;
    overflow: hidden;
    color: #2d2d2d;
    transition: .3s linear;
}

.plan-title {
    color: #006584;
    font-size: x-large;
}
.break-line {
    white-space: pre-wrap;
}
.card-preco:hover .card-preco-header {
    box-shadow: 0 0 0 100vh #006584;
}

.card-preco-header {
    background-color: #006584;
    display: inline-block;
    color: #fff;
    padding: 12px 30px;
    border-radius: 0 0 20px 20px;
    margin-left: 10vh;
    // text-transform: uppercase;
    font-weight: 600;
    transition: .4s line;
}

.preco {
    font-size: 70px;
    color: #006584;
    margin: 30px 0;
    margin-left: 5vh;
    transition: .2s linear;
}

.preco sup,
.preco span {
    font-size: 22px;
    font-weight: 700;
}

.card-preco:hover,
.card-preco:hover .preco {
    color: #fff;
}

.card-preco li {
    font-size: 16px;
    padding: 10px 0;
    text-transform: uppercase;
}

.btn {
    display: inline-block;
    margin-left: 2vh;
    margin-bottom: 0.5vh;
    border: 2px solid #006584;
    color: #006584;
    border-radius: 8px;
    text-transform: uppercase;
    font-weight: 500;
    transition: .3s linear;
}

.btn:hover {
    background-color: #006584;
    color: #fff;
}


@media screen and (max-width: 1100px) {
    .card-preco {
        flex: 50%;
    }
}