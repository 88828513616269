.btn-vermelho {
    width: 40vh;
    height: 60px;
    margin: 25px 0 0 0;
    border: 0;
    border-radius: 10px;
    background: $preto;
    color: $branco;
    font-size: 18px;
    cursor: pointer;
    &:disabled {
        cursor: no-drop;
        background: darken($preto, 20%);
    }
}