.login-main {
    height: 100vh;
    background: url("../../assets/images/healthcare-bg.jpeg") no-repeat;
    width: 100vw;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form {
    width: 340px;
    margin: 50px auto;
}

.login-form form {
    margin-bottom: 15px;
    background: #f7f7f7;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    padding: 30px;
}

.login-form h2 {
    margin: 0 0 15px;
}

.form-control,
.btn {
    min-height: 38px;
    border-radius: 2px;
}

.input-group-addon .fa {
    font-size: 18px;
}

.btn {
    font-size: 15px;
    font-weight: bold;
}

.bottom-action {
    font-size: 14px;
}

.containera {
    width: 60vh;
    min-height: 25vh;
    padding: 25px 40px;
    background: #ffffffb2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}