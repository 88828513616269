﻿@import "../../node_modules/bootstrap/scss/functions";

$blue-primary: #007194;
$blue-info: #00bac7;
$blue-dark: #006584;
$text-dark: #1b1b29;
$text-dark-medium: #242329;
$text-grey: #eff1f7;
$text-grey-medium: #828282;
$white: #ffffff;
$font-regular: "Poppins-Regular", Arial, Helvetica, sans-serif;
$font-medium: "Poppins-Medium", Arial, Helvetica, sans-serif;

@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/utilities";
