@import "../variables.scss";
.active-menu {
  font-family: $font-medium;
  font-size: 0.9rem;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  color: #ffffff !important;
  background-image: linear-gradient($blue-info, $blue-primary);
  text-align: center;
}
th {
  color: #5d5d5d;
  font-family: $font-medium;
}

td {
  color: $text-dark;
  font-family: $font-regular;
}

.card-header-custom {
  height: 2.2rem;
  background-image: linear-gradient(to right, #007095 35%, #00bac7);
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  justify-content: center;
  padding-left: 0.8rem;

  h1 {
    padding-top: 0.5rem;
    color: $text-grey;
    font-size: 1rem;
  }
}
